<template>
  <span class="spinner d-inline-block ">
    <span
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </span>
  </span>
</template>

<script setup>
import {
  toRefs, defineProps, computed,
} from 'vue';

// Props
const props = defineProps({
  size: {
    type: Number,
    validator(value) {
      return [20, 24, 40, 48].includes(value);
    },
    default: 24,
  },
});
const { size } = toRefs(props);

const sizePx = computed(() => `${size.value}px`);
</script>

<style lang="scss" scoped>
.spinner-border{
  vertical-align: middle;
  font-size: v-bind(fontSize);
  --bs-spinner-border-width: 0.15em;
}
.spinner{
  width: v-bind(sizePx);
  height: v-bind(sizePx);
}
</style>
