<template>
  <span
    class="icon material-symbols-outlined d-inline-block"
    :class="[{'dark':dark}]"
  >{{ name }}</span>
</template>

<script setup>
import {
  toRefs, defineProps, computed,
} from 'vue';

// Props
const props = defineProps({
  name: { type: String, default: '' },
  size: {
    type: Number,
    validator(value) {
      return [20, 24, 40, 48].includes(value);
    },
    default: 24,
  },
  dark: { type: Boolean, default: false },
});
const { name, size, dark } = toRefs(props);

const fontSize = computed(() => `${size.value}px`);
</script>

<style lang="scss" scoped>
.icon{
  vertical-align: middle;
  font-variation-settings: 'OPSZ' v-bind(size);
  font-size: v-bind(fontSize);
  width: v-bind(fontSize);
  aspect-ratio: 1/1;
  &.dark{
    font-variation-settings: 'GRAD' -25;
  }
}
</style>
